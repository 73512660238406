<div class="channel-row" [ngStyle]="{'gap': threadMsg ? '20px' : '0px'}"
    [ngClass]="{'menu-opened': menuOpen, 'menu-closed': !menuOpen}">
    <mat-card class="channel-card" *ngIf="isChannelVisible">

        @if (chat === 'channel') {
        <app-header-channel [channel]="currentChannel" [currentChannelID]="currentChannelID"></app-header-channel>
        <main class="chat-space" [ngClass]="{'chat-space-empty': (channelMessages.length <= 0)}">
            @if (channelMessages.length <= 0) { <app-channel-msg [currentChannelID]="currentChannelID" [channel]="currentChannel">
                </app-channel-msg>
                }

                @for (message of channelMessages; track $index) {
                @if(checkTimeStemp(message.date ,$index ,'channel')){
                <div class="day-section">
                    <hr>
                    <div>{{ newTimeStemp }}</div>
                    <hr>
                </div>
                }


                <app-message [msg]="message" [user]="dataService.getUserFromMessage(message)" [isChannelMsg]="true"
                    (threadOutput)="setThreadValues($event)"></app-message>
                }
        </main>
        }

        @else if (chat === 'message') {
        <app-direct-message [user]="chatUser"></app-direct-message>
        <main class="chat-space">
            @if (directMessages.length <= 0) { <app-direct-msg [user]="chatUser"></app-direct-msg>}

                @for (message of directMessages; track $index) {
                @if(checkTimeStemp(message.date ,$index ,'message')){
                <div class="day-section">
                    <hr>
                    <div>{{ newTimeStemp }}</div>
                    <hr>
                </div>
                }


                <app-message [msg]="message" [user]="dataService.getUserFromMessage(message)" [isChannelMsg]="true"
                    (threadOutput)="setThreadValues($event)"></app-message>
                }
        </main>
        }

        @else {
        <app-header-new-msg></app-header-new-msg>
        <main class="chat-space">

        </main>
        }

        <app-input-textarea [channel]="currentChannel" [chatUserId]="getChatUserId()" [chat]="chatInput"
            [members]="currentChannelMembers"></app-input-textarea>

    </mat-card>

    @if (chat === 'channel' || chat === 'message') {
    <app-threads [chatMsg]="threadMsg" [channels]="channels" (closeThread)="deletThreadValues($event)"
        [currentChannelMembers]="currentChannelMembers" *ngIf="isThreadVisible"></app-threads>
    }

</div>