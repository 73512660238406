<mat-card>

    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

    <header>
        <mat-card-title>Channel erstellen</mat-card-title>
        <button mat-icon-button disableRipple (click)="onNoClick()">
            <mat-icon>close</mat-icon>
        </button>
    </header>

    <div>
        <span>Channels dienen deinem Team zur Kommunikation. Am besten sollten sie themenbezogen sein <br>#marketing zum
            Beispiel.</span>
    </div>

    <!-- Channel-Name Eingabefeld mit Fehlermeldung -->
    <div class="section">
        <header>
            <span class="section-title">Channel-Name</span>
        </header>
        <div class="channel-edit-inp">
            <img src="./../../../../../assets/img/icons/hash_bk_18.png" alt="">
            <input type="text" [(ngModel)]="newChannel.name" placeholder="z.B. Kooperationsprojekte"
                (ngModelChange)="validateInputChannelName()">
        </div>
        <div class="error-message">{{ channelNameError }}</div>
    </div>

    <!-- Beschreibung Eingabefeld mit Fehlermeldung -->
    <div class="section">
        <header>
            <span class="section-title">Beschreibung</span>
        </header>
        <div class="channel-edit-inp">
            <input type="text" [(ngModel)]="newChannel.description" placeholder="Dein Text hier"
                (ngModelChange)="validateInputChannelDescription()">
        </div>
        <div class="error-message">{{ descriptionError }}</div>
    </div>

    <!-- Button mit dynamischer Klasse -->
    <button class="btn" [ngClass]="{'btn-enable': canSaveNewChannel(), 'btn-disable': !canSaveNewChannel()}"
        (click)="saveNewChannel()" [disabled]="!canSaveNewChannel()">Erstellen</button>
</mat-card>