<mat-card>

    <header>
        <mat-card-title>Achtung!</mat-card-title>
    </header>

    <span>{{ data.errorMessage }}</span>

    <button class="btn" (click)="onNoClick()">Ok</button>

</mat-card>