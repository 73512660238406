<div class="background-container">
  <div class="background-container__logo">
    <img src="./assets/img/logo_+_text.png" />
  </div>

  <mat-card class="imprint" [@slideAnimation]="animationState">
    <mat-card-content>
      <div class="imprint__header">
        <div class="imprint__arrow-back">
          <mat-icon (click)="openLogin()">arrow_back</mat-icon>
        </div>
        <span class="imprint__headline">Impressum</span>

        <div class="imprint__info">
          <h3 class="imprint__adress">Angaben gemäß § 5 TMG</h3>
          <p>Tobias Termer</p>
          <h4 class="imprint__adress">Postanschrift</h4>
          <p>Warenerstr. 1<br />31832 Springe<br /></p>
          <h4 class="imprint__adress">Kontakt</h4>
          <p>
            Telefon: +49 1573 5686236<br />E-Mail: mail&#64;tobias-termer.de
          </p>
          <h4 class="imprint__adress">Hinweise zur Website</h4>
          <p>
            Das Impressum wurde mit dem<br>
            <a
              target="_blank"
              href="https://www.activemind.de/datenschutz/impressums-generator/"
              >Impressums-Generator der activeMind AG</a
            >
            erstellt.
          </p>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
