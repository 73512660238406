<div class="background-container">
    <div class="background-container__logo mobile-hidden">
      <img src="./assets/img/logo_+_text.png" />
    </div>
  
    <mat-card class="privacy" [@slideAnimation]="animationState">
        <mat-card-content>
          <div class="privacy__header">
            <div class="privacy__arrow-back">
              <mat-icon class="icon__arrow-back" (click)="openLogin()">arrow_back</mat-icon>
            </div>
            <h1 class="privacy__headline mobile-hidden">Datenschutzerklärung</h1>
            <h1 class="privacy__headline desktop-hidden">Datenschutz-<br>erklärung</h1>
          </div>
          <p>
            Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der
            EU-Datenschutzgrundverordnung (DSGVO), ist:
          </p>
          <p>Tobias Termer</p>
          <h2>Ihre Betroffenenrechte</h2>
          <p>
            Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten
            können Sie jederzeit folgende Rechte ausüben:
          </p>
          <ul>
            <li>
              Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung
              (Art. 15 DSGVO),
            </li>
            <li>
              Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),
            </li>
            <li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
            <li>
              Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund
              gesetzlicher Pflichten noch nicht löschen dürfen (Art. 18 DSGVO),
            </li>
            <li>
              Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO)
              und
            </li>
            <li>
              Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt
              haben oder einen Vertrag mit uns abgeschlossen haben (Art. 20 DSGVO).
            </li>
          </ul>
          <p>
            Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese
            jederzeit mit Wirkung für die Zukunft widerrufen.
          </p>
          <p>
            Sie können sich jederzeit mit einer Beschwerde an eine Aufsichtsbehörde
            wenden, z. B. an die zuständige Aufsichtsbehörde des Bundeslands Ihres
            Wohnsitzes oder an die für uns als verantwortliche Stelle zuständige
            Behörde.
          </p>
          <p class="privacy__break">
            Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit
            Anschrift finden Sie unter:
            <a
              href="https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html"
              target="_blank"
              rel="noopener nofollow"
              >https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html</a
            >.
          </p>
          <h2>Erfassung allgemeiner Informationen beim Besuch unserer Website</h2>
          <h3>Art und Zweck der Verarbeitung:</h3>
          <p>
            Wenn Sie auf unsere Website zugreifen, d.h., wenn Sie sich nicht
            registrieren oder anderweitig Informationen übermitteln, werden
            automatisch Informationen allgemeiner Natur erfasst. Diese Informationen
            (Server-Logfiles) beinhalten etwa die Art des Webbrowsers, das
            verwendete Betriebssystem, den Domainnamen Ihres
            Internet-Service-Providers, Ihre IP-Adresse und ähnliches.
          </p>
          <p>Sie werden insbesondere zu folgenden Zwecken verarbeitet:</p>
          <ul>
            <li>
              Sicherstellung eines problemlosen Verbindungsaufbaus der Website,
            </li>
            <li>Sicherstellung einer reibungslosen Nutzung unserer Website,</li>
            <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
            <li>zur Optimierung unserer Website.</li>
          </ul>
          <p>
            Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre Person zu
            ziehen. Informationen dieser Art werden von uns ggfs. anonymisiert
            statistisch ausgewertet, um unseren Internetauftritt und die
            dahinterstehende Technik zu optimieren.
          </p>
          <h3>Rechtsgrundlage und berechtigtes Interesse:</h3>
          <p>
            Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis
            unseres berechtigten Interesses an der Verbesserung der Stabilität und
            Funktionalität unserer Website.
          </p>
          <h3>Empfänger:</h3>
          <p>
            Empfänger der Daten sind ggf. technische Dienstleister, die für den
            Betrieb und die Wartung unserer Webseite als Auftragsverarbeiter tätig
            werden.
          </p>
          <h3>Speicherdauer:</h3>
          <p>
            Die Daten werden gelöscht, sobald diese für den Zweck der Erhebung nicht
            mehr erforderlich sind. Dies ist für die Daten, die der Bereitstellung
            der Website dienen, grundsätzlich der Fall, wenn die jeweilige Sitzung
            beendet ist.
          </p>
          <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
          <p>
            Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder
            gesetzlich noch vertraglich vorgeschrieben. Ohne die IP-Adresse ist
            jedoch der Dienst und die Funktionsfähigkeit unserer Website nicht
            gewährleistet. Zudem können einzelne Dienste und Services nicht
            verfügbar oder eingeschränkt sein. Aus diesem Grund ist ein Widerspruch
            ausgeschlossen.
          </p>
          <h2>Registrierung auf unserer Website</h2>
          <h3>Art und Zweck der Verarbeitung:</h3>
          <p>
            Für die Registrierung auf unserer Website benötigen wir einige
            personenbezogene Daten, die über eine Eingabemaske an uns übermittelt
            werden.
          </p>
          <p>
            Zum Zeitpunkt der Registrierung werden zusätzlich folgende Daten
            erhoben:
          </p>
          <ul>
            <li>Vorname</li>
            <li>Name</li>
            <li>benutzerdefiniertes Passwort</li>
          </ul>
          <p>
            Ihre Registrierung ist für das Bereithalten bestimmter Inhalte und
            Leistungen auf unserer Website erforderlich.
          </p>
          <h3>Rechtsgrundlage:</h3>
          <p>
            Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt
            auf Grundlage einer Einwilligung des Nutzers (Art. 6 Abs. 1 lit. a
            DSGVO).
          </p>
          <h3>Empfänger:</h3>
          <p>
            Empfänger der Daten sind ggf. technische Dienstleister, die für den
            Betrieb und die Wartung unserer Website als Auftragsverarbeiter tätig
            werden.
          </p>
          <h3>Speicherdauer:</h3>
          <p>
            Daten werden in diesem Zusammenhang nur verarbeitet, solange die
            entsprechende Einwilligung vorliegt.
          </p>
          <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
          <p>
            Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig,
            allein auf Basis Ihrer Einwilligung. Ohne die Bereitstellung Ihrer
            personenbezogenen Daten können wir Ihnen keinen Zugang auf unsere
            angebotenen Inhalte gewähren.
          </p>
          <h2>Kommentarfunktion</h2>
          <h3>Art und Zweck der Verarbeitung:</h3>
          <p>
            Wenn Nutzer Kommentare auf unserer Website hinterlassen, werden neben
            diesen Angaben auch der Zeitpunkt ihrer Erstellung und der zuvor durch
            den Websitebesucher gewählte Nutzername gespeichert. Dies dient unserer
            Sicherheit, da wir für widerrechtliche Inhalte auf unserer Webseite
            belangt werden können, auch wenn diese durch Benutzer erstellt wurden.
          </p>
          <h3>Rechtsgrundlage:</h3>
          <p>
            Die Verarbeitung der als Kommentar eingegebenen Daten erfolgt auf der
            Grundlage eines berechtigten Interesses (Art. 6 Abs. 1 lit. f DSGVO).
          </p>
          <p>
            Durch Bereitstellung der Kommentarfunktion möchten wir Ihnen eine
            unkomplizierte Interaktion ermöglichen. Ihre gemachten Angaben werden
            zum Zwecke der Bearbeitung der Anfrage sowie für mögliche
            Anschlussfragen gespeichert.
          </p>
          <h3>Empfänger:</h3>
          <p>Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>
          <h3>Speicherdauer:</h3>
          <p>
            Die Daten werden gelöscht, sobald diese für den Zweck der Erhebung nicht
            mehr erforderlich sind. Dies ist grundsätzlich der Fall, wenn die
            Kommunikation mit dem Nutzer abgeschlossen ist und das Unternehmen den
            Umständen entnehmen kann, dass der betroffene Sachverhalt abschließend
            geklärt ist. Wir behalten uns die Löschung ohne Angaben von Gründen und
            ohne vorherige oder nachträgliche Information vor.
          </p>
          <p>
            Außerdem können Sie Ihren Kommentar jederzeit durch uns löschen lassen.
            Schreiben Sie dafür bitte eine E-Mail an den unten aufgeführten
            Datenschutzbeauftragten bzw. die für den Datenschutz zuständige Person
            und übermitteln den Link zu Ihrem Kommentar sowie zu
            Identifikationszwecken die bei der Erstellung des Kommentars verwendete
            E-Mail-Adresse.
          </p>
          <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
          <p>
            Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig.
            Ohne die Bereitstellung Ihrer personenbezogenen Daten können wir Ihnen
            keinen Zugang zu unserer Kommentarfunktion gewähren.
          </p>
          <h2>SSL-Verschlüsselung</h2>
          <p>
            Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden
            wir dem aktuellen Stand der Technik entsprechende
            Verschlüsselungsverfahren (z. B. SSL) über HTTPS.
          </p>
          <hr />
          <h2>Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO</h2>
          <h3>Einzelfallbezogenes Widerspruchsrecht</h3>
          <p>
            Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen
            Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender
            personenbezogener Daten, die aufgrund Art. 6 Abs. 1 lit. f DSGVO
            (Datenverarbeitung auf der Grundlage einer Interessenabwägung) erfolgt,
            Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmung
            gestütztes Profiling im Sinne von Art. 4 Nr. 4 DSGVO.
          </p>
          <p>
            Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht
            mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe
            für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und
            Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung,
            Ausübung oder Verteidigung von Rechtsansprüchen.
          </p>
          <h3>Empfänger eines Widerspruchs</h3>
          <p>Tobias Termer</p>
          <hr />
          <h2>Änderung unserer Datenschutzbestimmungen</h2>
          <p>
            Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie
            stets den aktuellen rechtlichen Anforderungen entspricht oder um
            Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen,
            z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt
            dann die neue Datenschutzerklärung.
          </p>
          <h2>Fragen an den Datenschutzbeauftragten</h2>
          <p>
            Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine
            E-Mail oder wenden Sie sich direkt an die für den Datenschutz
            verantwortliche Person in unserer Organisation:
          </p>
          <p>
            <em
              >Ein verwendetes Icon
              <a
                href="https://www.flaticon.com/de/kostenlose-icons/pdf"
                target="_blank"
                rel="noopener"
                >(pdf-Icon)</a
              >
              wurde von Google - Flaticon erstellt.</em
            >
          </p>
          <p>
            <em
              >Die Datenschutzerklärung wurde mithilfe der activeMind AG erstellt,
              den Experten für
              <a
                href="https://www.activemind.de/datenschutz/datenschutzbeauftragter/"
                target="_blank"
                rel="noopener"
                >externe Datenschutzbeauftragte</a
              >
              (Version #2020-09-30).</em
            >
          </p>
        </mat-card-content>
      </mat-card>
  </div>
  