<div class="dialog-container">

    <h2>Dein Profil bearbeiten</h2>
    <div class="avatar-container">
        <img [src]="data.user.avatar" (error)="onImageError($event)" alt="Avatar">
    </div>
    <div class="input-container">
        <div class="label-container">
            <img src="../../../../../assets/img/icons/account_circle_bk.png" class="icon" alt="">
            <span>Vollständiger Name</span>
        </div>
        <input type="text" [(ngModel)]="newName" matInput placeholder="{{ newName }}" (ngModelChange)="validateInputUserName()">
        <div class="error-message">{{ userNameError }}</div>
    </div>
    <div class="input-container">
        <div class="label-container">
            <img src="../../../../../assets/img/icons/mail.png" class="icon" alt="">
            <span>E-Mail-Adresse</span>
        </div>
        <input type="email" [(ngModel)]="newEmail" matInput placeholder="{{ newEmail }}" (ngModelChange)="validateInputUserEmail()">
        <div class="error-message">{{ userEmailError }}</div>
    </div>
    <div class="button-container">
        <div mat-dialog-actions>
            <button mat-flat-button class="btn-cancel" (click)="closeDialog()">Abbrechen</button>
            <button mat-flat-button class="btn-save" color="primary"
                [ngClass]="{'btn-enable': canSaveUser(), 'btn-disable': !canSaveUser()}" (click)="saveUser()"
                [disabled]="!canSaveUser()">Speichern</button>
        </div>
    </div>
    <img src="../../../../../assets/img/icons/close.png" class="btn-close" (click)="closeDialog()" alt="close">
</div>